import * as React from "react"
import { graphql } from "gatsby"
import sizzle from '../videos/sizzle_1.mp4'
import Marquee from "react-fast-marquee"
import '../../node_modules/@splidejs/react-splide/dist/css/splide.min.css'

import GWBMission from "../components/gwbMission"
import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroImg from "../images/hero-1.png"

const PostIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  
  const posts = data.webiny.listPost.data
  const talents = data.webiny.listTalent.data
  const categories = data.webiny.listCategory.data
  const platforms = data.webiny.listPlatform.data

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        <p>
          {/* No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js). */}
          No blog posts found.
        </p>
      </Layout>
    )
  }
  

  return (
    <Layout location={location} title={siteTitle} className="bg-black">
      <Seo title="Home" />
      <div className="">
            <div className="h-120 md:h-[51.25rem] overflow-hidden relative lg:bg-none bg-cover bg-center" style={{ backgroundImage: `url(${HeroImg})` }}>

              <video width="100%" 
                autoPlay 
                loop 
                muted 
                playsInline 
                className="hidden md:inline-block mt-10 lg:mt-0"
              >
                <source
                  src={sizzle}
                  type="video/mp4"
                />
              </video>
              <img src={HeroImg} className="hidden lg:inline-block w-full z-0" />

              <div className="px-5 bottom-5 lg:p-0 absolute lg:bottom-10 lg:left-5 z-30">
                <div className="w-full lg:pl-2 lg:max-w-4xl">
                  <h1 itemProp="headline" className="knockoutFont text-6xl md:text-8xl uppercase">CULTURE THRIVES HERE</h1>
                </div>
              </div>
              <div className="h-72 lg:h-96 bg-gradient-to-t from-black absolute w-full bottom-0 z-20" />
            </div>
      </div>

      <Marquee
        autoFill 
        gradient={false} 
        className="w-full uppercase bg-gray-300 text-black TitlingGothicFBWideFont py-2 border-b-2 border-t-2 border-black">
           <div className="p-2">&nbsp;&nbsp;ENTERTAINMENT. COMMUNITY. BRAND STORYTELLING.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;//</div>
      </Marquee>

      {/* <div className="py-5 lg:p-5 bg-white text-black">
        <div className=""></div>
        <h2 className="knockoutFont uppercase text-4xl tracking-wide pl-2">Launching Point</h2>
        <div className="grid">
          <div className="overflow-auto">
            <Splide 
              options={ {
                rewind: true,
                pagination: false,
                width : '100%',
                perPage: 4,
                gap   : '0.5rem',
                drag   : 'free',
                breakpoints: {
                  1024: {
                    perPage: 2,
                  },
                  768: {
                    perPage: 1,
                    snap: false,
                  },
                },
              } }
              aria-label="latest-projects">
                
                {posts.map(post => {
                return (post.category.title === 'Launching Point' && post.visibility) ?
                    <SplideSlide key={post.entryId} className="py-2">
                    <div className="w-full">
                      <article
                            className="p-2"
                            itemScope
                            itemType="http://schema.org/Article"
                          >
                            <Link to={post.slug} itemProp="url">
                              <button className="rounded-lg w-full p-1 inline-block transition duration-300 ease-out hover:ease-in hover:bg-neon-green hover:outline-black">
                                <div className="post-headline rounded-lg w-full p-1 ">
                                {post.video ? 
                                  <video 
                                    // onMouseOver={videoHoverIn}
                                    // onMouseOut={videoHoverOut}
                                    // onmouseOver={event => event.target.load()} 
                                    poster={post.image} 
                                    loop muted playsInline 
                                    id="postVideo"
                                    className="h-full w-full rounded-xl drop-shadow-card hover:drop-shadow-none"
                                  >
                                  <source
                                    src={post.video}
                                    type="video/mp4"
                                  />
                                </video>
                                : <img src={gwbCover} className="" /> }
                                </div>
                              </button>
                              <h2 className="text-xs leading-normal text-left p-2 font-semibold">
                                  <span itemProp="headline" className="">{post.title}</span>
                              </h2>
                            </Link>
                          </article>
                    </div>
                    </SplideSlide>
                  :
                    null
                })}

            </Splide>
          </div>
        </div>
      </div> */}


      {/* <div className="py-5 lg:p-5 bg-black">
        <h2 className="knockoutFont uppercase text-4xl tracking-wide pl-2">All EPISODES</h2>
        <div className="">
          <div className="overflow-auto">

            <Splide 
              options={ {
                rewind: true,
                type   : 'loop',
                padding: { left: 50, right: 50 },
                pagination: false,
                width : '100%',
                perPage: 3,
                drag   : 'free',
                autoplay : true,
                breakpoints: {
                  1024: {
                    perPage: 2,
                  },
                  768: {
                    perPage: 1,
                    snap: false,
                  },
                },
              } }
              aria-label="latest-projects">
                {posts.map(post => {
                return (post.visibility) ?
                    <SplideSlide key={post.entryId} className="py-2">
                      <div className="w-full">
                        <article
                              className="p-2"
                              itemScope
                              itemType="http://schema.org/Article"
                            >
                              <Link to={post.slug} itemProp="url">
                                <button className="rounded-lg w-full p-1 inline-block transition duration-300 ease-out hover:ease-in hover:bg-neon-green hover:outline-black">
                                  <div className="w-full p-1 text-center">
                                  {post.video ? 
                                    <video 
                                      onMouseOver={videoHoverIn}
                                      onMouseOut={videoHoverOut}
                                      onmouseOver={event => event.target.load()} 
                                      poster={post.image} 
                                      loop muted playsInline 
                                      className="h-full w-full max-h-[17.75rem] rounded-lg drop-shadow-card hover:drop-shadow-none"
                                    >
                                    <source
                                      src={post.video}
                                      type="video/mp4"
                                    />
                                  </video>
                                  : <img src={gwbCover} className="" /> }
                                  </div>
                                </button>
                                <div className="px-2">
                                  <h2 className="text-xs leading-normal text-left py-2 font-semibold">
                                      <span itemProp="headline" className="">{post.title}</span>
                                  </h2>
                                  <div className="py-2">
                                    <span className="bg-green-500 p-1 text-black text-xs font-semibold">{post.category.title}</span>
                                  </div>
                                  <div className="text-xs py-2">
                                    <DateFormatter dateString={post.createdOn} />
                                  </div>
                                  <section className="text-xs">
                                    <p>{post.description}</p>
                                  </section>
                                </div>
                              </Link>
                              
                            </article>
                      </div>
                    </SplideSlide>
                    :
                    null
                })}
            </Splide>
          </div>
        </div>
      </div> */}

      <GWBMission />

      
      <div className="flex py-5 bg-gray-300 border-t-2 border-b-2 border-black">
        <Marquee
          autoFill 
          pauseOnHover
          direction="left"
        >
          {talents.map(talent => {
            return (talent.visibility) ? 
                    <div key={talent.entryId} className="grid grid-cols-2 gap-4">
                        <div className="uppercase text-black knockoutFont text-5xl flex px-5 items-center justify-center">{talent.name}</div>
                        <div className="overflow-hidden h-28 w-48 bg-no-repeat bg-cover bg-center border-2 border-black" style={{ backgroundImage: `url(${talent.image})` }} />
                    </div>
            :
                null
            })}
        </Marquee>
      </div>
      <div className="flex py-5 bg-gray-300 border-b-2 border-black">
        <Marquee
          autoFill 
          pauseOnHover
          direction="right"
        >
          {platforms.map(platform => {
            return (platform.visibility) ? 
            <a href={platform.url} target="_blank">
              <div key={platform.entryId} className="grid grid-cols-2 gap-4">
                  <div className="uppercase text-black knockoutFont text-5xl flex px-5 items-center justify-center">{platform.title}</div>
                  <div className="overflow-hidden h-28 w-48 bg-no-repeat bg-cover bg-center border-2 border-black" style={{ backgroundImage: `url(${platform.image})` }} />
              </div>
            </a>
            :
              null
            })}
        </Marquee>
      </div>
      <div className="flex py-5 bg-gray-300">
        <Marquee
          autoFill 
          pauseOnHover
          direction="left"
        >
          {categories.map(category => {
            return (category.visibility) ? 
              <a href={category.url} target="_blank">
                <div key={category.entryId} className="grid grid-cols-2 gap-4">
                    <div className="uppercase text-black knockoutFont text-5xl flex px-5 items-center justify-center">{category.title}</div>
                    <div className="overflow-hidden h-28 w-48 bg-no-repeat bg-cover bg-center border-2 border-black" style={{ backgroundImage: `url(${category.image})` }} />
                </div>
              </a>
            :
                null
            })}
        </Marquee>
      </div>

    </Layout>
  )
}

export default PostIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    webiny {
      listPost(sort: createdOn_ASC) {
        data {
          id
          entryId
          title
          slug
          description
          createdOn
          image
          video
          visibility
          featurePost
          author {
            name
            image
          }
          category {
            entryId
            title
          }
        }
      }
      listTalent(sort: createdOn_ASC) {
        data {
          name
          description
          visibility
          image
          entryId
        }
      }
      listPlatform(sort: createdOn_ASC) {
        data {
          title
          description
          image
          url
          visibility
          entryId
        }
      }
      listCategory(sort: createdOn_ASC) {
        data {
          title
          image
          visibility
          entryId
          url
        }
      }
    }
  }
`
