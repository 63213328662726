import React from 'react'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'

import GWBImg1 from '../images/mission/gwb-img-1.jpg'
import GWBImg2 from '../images/mission/gwb-img-2.jpg'
import GWBImg3 from '../images/mission/gwb-img-3.jpg'
import GWBImg4 from '../images/mission/gwb-img-4.jpg'
import GWBImg5 from '../images/mission/gwb-img-5.jpg'
import GWBImg6 from '../images/mission/gwb-img-6.jpg'
import MissionTitle from '../images/mission/mission.svg'


const GWBMission = () => {
  
  return (
    <ParallaxProvider>
    <div className="overflow-hidden relative h-132 lg:h-auto">

      <Parallax speed={10}>
        <div className="relative top-10 left-2 md:left-96 z-20">
          <img src={MissionTitle} className="absolute w-full md:w-4/6" />
        </div>
      </Parallax>

      <div class="absolute z-10 py-20 h-full lg:py-72">
          <div className="h-40 lg:h-96 bg-gradient-to-b from-black absolute w-full top-0 z-0" />
          <div className="px-5 pt-36 w-full sm:w-5/6 md:w-4/6 lg:w-7/12 xl:w-8/12 sm:p-12 lg:p-10 xl:p-14 z-40 h-full relative">
            <h2 className="tracking-tight font-medium text-6xl sm:text-7xl md:text-6xl lg:text-7xl xl:text-8xl knockoutFont uppercase text-neon-green mb-2 lg:mb-5">GWB IS A COLLECTIVE</h2>
            <p className="text-xl leading-6 mb-5 lg:mb-10">A platform for established and upcoming talent to express the shared experience of Black nerd culture. As the impact of gaming and the creator economy resonates across the zeitgeist, we are making sure Black voices are heard, discovered, and celebrated.</p>
          </div>
          <div className="h-full lg:h-96 bg-gradient-to-t from-black absolute w-full bottom-0 z-0" />
      </div>

      <div className="overflow-hidden h-100 lg:h-screen relative top-0">
        <Parallax speed={-20}>
          <img src={GWBImg1} className="absolute w-56 md:w-2/12 left-10 bottom-40 md:-bottom-[600px] z-40 shadow-lg" />
        </Parallax>
        <Parallax speed={-20}>
          <img src={GWBImg2} className="absolute w-60 md:w-3/12 bottom-48 md:-bottom-[500px] right-5 shadow-lg" />
        </Parallax>
        <Parallax speed={-25}>
          <img src={GWBImg6} className="absolute w-60 md:w-3/12 blur-sm right-4 md:right-96 top-64 md:top-24 shadow-lg z-0" />
        </Parallax>
        <Parallax speed={-15}>
          <img src={GWBImg3} className="absolute w-60 md:w-3/12 blur-sm left-10 md:left-96 -bottom-[700px] shadow-lg z-10" />
        </Parallax>
        <Parallax speed={-10}>
          <img src={GWBImg4} className="absolute w-40 md:w-3/12 top-40 md:top-0 right-2 md:right-10 z-30 shadow-lg" />
        </Parallax>
        <Parallax speed={-10}>
          <img src={GWBImg5} className="absolute w-60 md:w-3/12 top-0- md:-top-16 md:left-5" />
        </Parallax>
      </div>

      <Parallax speed={-10}>
        <div className="relative bottom-5 md:bottom-36 left-10 md:left-70 z-20">
          <img src={MissionTitle} className="absolute w-4/6" />
        </div>
      </Parallax>

    </div>
    </ParallaxProvider>

  )
}

export default GWBMission